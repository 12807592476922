<template>
  <div class="row">
    <div v-show="editable&&!disabled" class="col-12">
      <q-btn-group outline class="float-right">
        <!-- 공정[' + process.processName + '] 추정완료' -->
        <c-btn 
          v-show="process.estimationFlag!=='Y'&&!btnEditable"
          :isSubmit="isEstimationComplete"
          :url="estimationCompleteUrl"
          :param="saveProcess"
          mappingType="PUT"
          :label="$comm.getLangLabel('LBL0001191', {s1:process.processName})" 
          icon="check"
          @beforeAction="estimationCompleteProcess"
          @btnCallback="estimationCompleteProcessCallback" />
        <!-- 평가완료 -->
        <c-btn 
          v-show="process.estimationFlag==='Y'&&!btnEditable"
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="saveProcess"
          mappingType="PUT"
          label="LBL0001162" 
          icon="check"
          @beforeAction="completeProcess"
          @btnCallback="completeProcessCallback" />
      </q-btn-group>
    </div>
    <div class="col-12">
      <c-tab
        type="tabcard"
        align="left"
        :tabItems="tabItems"
        :height="tabHeight"
        :inlineLabel="true"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :key="tab.componentKey"
            :process.sync="process"
            :param="param"
            :height="height"
            :btnEditable="btnEditable"
            :updateBtnData="updateBtnData"
            :estimationUpdateBtnData="estimationUpdateBtnData"
            @research="research"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'charm-action-detail',
  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        assessUserName: '',  // 평가 담당자
        temperature: null,
        workMeasurementPlanId: '',
        measPlanContent: '',
        estimationFlag: '',
        charmResultScenarios: [],
        charmEstimationScenarios: [],
        estimationReCheckedCnt:0,
        resultReCheckedCnt:0,
      }),
    },
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        title: 'LBL0001164', // 평가
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      estimationCompleteUrl: '',
      completeUrl: '',
      isEstimationComplete: false,
      isComplete: false,
      saveProcess: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        charmResultScenarios: [],
        charmEstimationScenarios: [],
      },
      estimationUpdateBtnData: {
        title: 'LBL0001204',
        flag: false,
        research: '',
      },
      tab: '1'
    };
  },
  computed: {
    disabled() {
      // (평가실행 or 감소대책 계획 및 실행이) 아니거나 공정평가가 완료된 경우
      return !(this.param.ramStepCd === 'RRS0000005' || this.param.ramStepCd === 'RRS0000010') 
        || this.process.ramProcessAssessStepCd === 'RPA0000005'
    },
    tabHeight() {
      let margin = 80;
      let _height = Number(this.$_.replace(this.height, 'px', ''));
      if (this.editable && !this.disabled && !this.btnEditable) {
        _height = _height - 20
      }
      return String(_height - margin) + 'px';
    },
    tabItems() {
      return [
        {
          name: '1',
          icon: 'calculate',
          label: 'LBL0001125', // 위험도 추정
          component: () => import(`${'./charmRiskEstimation.vue'}`),
        },
        {
          name: '2',
          icon: 'gavel',
          label: 'LBL0001192', // 위험도 결정
          component: () => import(`${'./charmScenario.vue'}`),
          disabled: this.process.estimationFlag !== 'Y'
        }
      ];
    },
    btnEditable() {
      return this.editable && this.param.ramStepCd === 'RRS0000010' && this.process.ramProcessAssessStepCd === 'RPA0000005'
    },
  },
  watch: {
    'process.estimationFlag'() {
      this.tab = this.process.estimationFlag === 'Y' ? '2' : '1'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.estimationCompleteUrl = transactionConfig.ram.assessProcess.estimationComplete.url;
      this.completeUrl = transactionConfig.ram.assessProcess.complete.url;
      // code setting
      this.tab = this.process.estimationFlag === 'Y' ? '2' : '1'
      // list setting
    },
    estimationCompleteProcess() {
      let isProgress = true;
      this.$_.forEach(this.process.charmEstimationScenarios, sc => {
        if (!sc.riskEstimation) {
          isProgress = false
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            // 화학자재[' + sc.materialName + ']/구성물질[' + sc.chemName + ']에 위험도 추정이 되지 않았습니다.
            message: this.$comm.getLangMessage('MSG0000401', {s1: sc.materialName, s2: sc.chemName}),
            type: 'warning', // success / info / warning / error
          });
          return false;
        }
      })

      if (isProgress) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGCOMPLETE', // 완료하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.process.chgUserId = this.$store.getters.user.userId

            this.saveProcess = this.$_.cloneDeep(this.process);
            this.isEstimationComplete = !this.isEstimationComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    estimationCompleteProcessCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('assessComplete', _result.data)
    },
    completeProcess() {
      let isProgress = true;
      this.$_.forEach(this.process.charmResultScenarios, sc => {
        if (!sc.afterRamMatrixRiskId || !sc.assessDate || !sc.assessUserName) {
          isProgress = false
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            // '화학자재[' + sc.materialName + ']에 개선 후 위험도/평가자/평가일이 입력되지 않았습니다.'
            message: this.$comm.getLangMessage('MSG0000402', {s1: sc.materialName}),
            type: 'warning', // success / info / warning / error
          });
          return false;
        }
      })

      if (isProgress) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGCOMPLETE', // 완료하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.process.chgUserId = this.$store.getters.user.userId
            this.process.ramProcessAssessStepCd = 'RPA0000005';

            this.saveProcess = this.$_.cloneDeep(this.process);
            this.isComplete = !this.isComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeProcessCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('assessComplete', _result.data)

      this.updateBtnData.research = uid();
    },
    research() {
      this.$emit('research', 'scenario')
    },
  }
};
</script>